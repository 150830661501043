import React from "react";
import * as styles from "./text-with-icon.module.scss"

export default function TextWithIcon({Icon, text, href}) {
    if (text) {
        text = text.split('\n').map(str => <div className={styles.text}>{str}</div>)
    }
    return <div className={styles.textWithIcon}>
        <Icon/>
        {href ? <a href={href} target="_blank" className={styles.textContainer}> {text} </a> :
            <div className={styles.textContainer}> {text} </div>}
    </div>
}
