import React from "react"
import * as styles from "./contact-section.module.scss"
import {Container, Row, Col} from "react-bootstrap";
import VerticalSpacingRow from "../../components/vertical-spacing/vertical-spacing";
import VerticalSpacingSmallRow from "../../components/vertical-spacing-small/vertical-spacing-small";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import TextWithIcon from "../../components/text-with-icon/text-with-icon";
import MailIconSVG from "../../svg/icons/email-icon.svg"
import LocationIconSVG from "../../svg/icons/location-icon.svg"
import PhoneIconSVG from "../../svg/icons/phone-icon.svg"

export default function ContactSection(props) {

    const data = useStaticQuery(graphql`{
    imagePlaceholder: file(relativePath: {eq: "beefteka/map-placeholder.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 50, layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
  }`);

    return <>
        <Container>
            <VerticalSpacingRow/>
            <VerticalSpacingSmallRow/>
            <Row>
                <Col md={{span: 6, offset: 0, order: 1}} xs={{span: 12, order: 1}}>
                    <div className={styles.header}>
                        Skontaktuj się z nami
                    </div>
                    <VerticalSpacingSmallRow/>
                    <TextWithIcon href="https://goo.gl/maps/Qq7qVwT6wXh4cRQK9"
                                  text={"Henryka Sienkiewicza 35\n62 – 600 Koło"} Icon={LocationIconSVG}/>
                    <TextWithIcon href="mailto:kontakt@beefteka.pl" text={"kontakt@beefteka.pl"} Icon={MailIconSVG}/>
                    <TextWithIcon href="tel:+48 535 76 76 76" text={"+48 535 76 76 76"} Icon={PhoneIconSVG}/>
                </Col>
                <VerticalSpacingSmallRow/>
                <Col md={{span: 6, offset: 0, order: 2}} xs={{span: 12, order: 2}}>
                    <div className={styles.map}>
                        <iframe title="map" className={styles.map} width="100%" height="100%" style={{
                            border: 0,
                            position: 'absolute',
                            zIndex: 1
                        }} loading="eager" allowFullScreen
                                src={"https://www.google.com/maps/embed/v1/place?q=place_id:ChIJU1n5bkIVG0cRNlzfmvoBZCU&key=AIzaSyCwajEs8nWicvYvCZ5BuOUll53jabF6lD4&language=pl&zoom=14"}/>
                        <GatsbyImage
                            width="100%"
                            height="100%"
                            className={styles.map}
                            image={data.imagePlaceholder.childImageSharp.gatsbyImageData}
                            objectFit="cover"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    </>
}
