import React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/layout/layout";
import ContactSection from "../page-sections/contact-section/contact-section";

export default function Contact() {
    return <Layout>
        <Helmet>
            <title>Kontakt - Beefteka</title>
        </Helmet>
        <ContactSection/>
    </Layout>
}
